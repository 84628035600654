<div
  class="card"
  [ngClass]="{
    disabled: !hasRedirect,
    'card-bg-color-picasso': claim.claimType === claimType.RESTRUC,
    'card-bg-color-green-bis': managementEventCodesGreenColor.includes(this.claim.managementEventCode)
  }"
  (click)="goToClaimDetails()"
  pTooltip="{{claim.remainingDays !== undefined ? getClaimDaysLeftText($any(claim.remainingDays)): ''}}"
  tooltipPosition="top"
  [tooltipDisabled]="claim.remainingDays === undefined"
>
  <div class="info-circle"
       *ngIf="claim.compensationEndDate
              && showCompletenessInfo
              && isRecentCompleteness(claim.status, claim.createdDate, claim.compensationEndDate)">
    <p-tag styleClass="mr-5" icon="pi pi-info-circle" severity="warning" value="Complétude récente"></p-tag>
  </div>

  <div class="card-header">
    <div class="card-header__title">
      <h3>{{ claim.companyName }}</h3>
    </div>
    <div class="card-header__icon">
      <mat-icon
        *ngIf="claim.claimAlert; else downArrow"
        [ngStyle]="{ fill: claim.claimAlert }"
        svgIcon="alert"
      ></mat-icon>
      <ng-template #downArrow>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </ng-template>
    </div>
  </div>

  <div class="card-content">
    <p>{{ claim.creditEventLabel }}</p>
    <p>{{ claim.motif }}</p>
    <p>{{ claim.subMotif }}</p>
    <p>{{ getPaymentTypeFormatText() }}</p>
    <p>
      <span class="card-content__label">{{
        "dashboard.claim-card.siren" | transloco
      }}</span>
      {{ claim.siren }}
    </p>
    <p *ngIf="claim.contractReference">
      <span class="card-content__label">{{
        "dashboard.claim-card.ref-credit" | transloco
      }}</span>
      {{ claim.contractReference }}
    </p>
    <p>
      <span class="card-content__label">{{
        "dashboard.claim-card.montant" | transloco
      }}</span>
      {{
        claim.loanAmount
          | translocoCurrency: "symbol":{ minimumFractionDigits: 0 }
      }}
    </p>
    <p>
      <span class="card-content__label">{{
        "dashboard.claim-card.bank" | transloco
      }}</span>
      {{ claim.bankName }}
    </p>
    <ng-container *ngIf="claim && claim.claimCreationDateInBRP">
      <p>
        <span class="card-content__label">{{
          "dashboard.claim-card.bank-compensated-amount" | transloco
        }}</span>
        {{ claim.bankCompensatedAmount | translocoCurrency }}
      </p>
      <p>
        <span class="card-content__label">{{
          "dashboard.claim-card.claim-creation-date" | transloco
        }}</span>
        {{ claim.claimCreationDateInBRP | translocoDate }}
      </p>
    </ng-container>
  </div>

  <div class="card-footer">
    <p>
      <span class="card-content__label">{{
          "dashboard.claim-card.claim-status" | transloco
        }}</span>
      {{ toStatusLabel() | transloco }}
    </p>

    <div *ngIf="claim.userAssignedFirstName" class="card-footer__charge">
      <mat-icon>person</mat-icon>
      {{ claim.userAssignedFirstName }} {{ claim.userAssignedLastName }}
    </div>

    <p *ngIf="claim.lastStatusDate">
      <span class="card-content__label">{{
        "dashboard.claim-card.creation-date" | transloco
      }}</span>
      {{ claim.lastStatusDate | date: "dd-MM-yyyy" }}
    </p>

    <p *ngIf="claim.lastClaimUpdateDate">
      <span class="card-content__label">{{
        "dashboard.claim-card.compensation-end-date" | transloco
      }}</span>
      {{ claim.lastClaimUpdateDate | date: "dd-MM-yyyy" }}
    </p>
  </div>

  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

</div>
