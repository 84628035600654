import { ContractClaimStatusTranslation } from "@app/contracts/types/contract";
import { ClaimExcerpt, OtherClaim } from '@app/dashboard/types/claims';
import { ClaimType } from "@app/shared/types/claim-types";
import { ContractDTO } from '@app/shared/types/contract';
import {
  ContractAssociatedClaim,
  ContractDetails,
  ContractDetailsDTO,
  ContractObject,
  PaymentCodeTypes,
  PaymentCodeTypesLabelTranslation,
} from '@app/shared/types/details';
import { FieldsValueCode } from '@app/shared/types/field-code';

export const toContractDetails = (detailData: ContractDTO, otherData?: {product?: string}): ContractDetails => {
  const commissionSlices = detailData.commissionSlices?.sort(
    (a, b) => a.maximalIndex - b.maximalIndex
  );
  return {
    [FieldsValueCode.AMORTIZATION_MODE]: detailData.amortizationMode,
    [FieldsValueCode.BALANCE_SHEET]: detailData.balanceSheet,
    [FieldsValueCode.BANK_CODE]: detailData.bankCode,
    [FieldsValueCode.BANK_NAME]: detailData.bankName,
    [FieldsValueCode.BORROWER_ADDRESS_FULL]: [
      detailData.borrowerAddress1,
      detailData.borrowerAddress2,
      detailData.borrowerAddress3,
      detailData.borrowerCity,
      detailData.borrowerPostCode,
    ]
      .filter(Boolean)
      .join(', '),
    [FieldsValueCode.BORROWER_ADDRESS1]: detailData.borrowerAddress1,
    [FieldsValueCode.BORROWER_ADDRESS2]: detailData.borrowerAddress2,
    [FieldsValueCode.BORROWER_ADDRESS3]: detailData.borrowerAddress3,
    [FieldsValueCode.BORROWER_CITY]: detailData.borrowerCity,
    [FieldsValueCode.BORROWER_FIRST_NAME]: detailData.borrowerFirstName,
    [FieldsValueCode.BORROWER_LAST_NAME]: detailData.borrowerLastName,
    [FieldsValueCode.BORROWER_POST_CODE]: detailData.borrowerPostCode,
    [FieldsValueCode.CHECKED_CONTRACT_IND]: detailData.checkedContractInd,
    [FieldsValueCode.COMPANY_NAME]: detailData.companyName,
    [FieldsValueCode.CONTRACT_STATUS]: detailData.financingContractStatus,
    [FieldsValueCode.CURRENT_RISK]: detailData.currentRisk,
    [FieldsValueCode.EMPLOYEES_NUMBER]: detailData.employeesNumber,
    [FieldsValueCode.FEES_RATE]: detailData.feesRate,
    [FieldsValueCode.FREQUENCY]: detailData.frequency,
    [FieldsValueCode.WARRANTY_CONTRACT_ID]: detailData.warrantyContractId as string,
    [FieldsValueCode.FINANCING_CONTRACT_ID]: detailData.financingContractId as string,
    [FieldsValueCode.INNOVATIVE_COMPANY_IND]: detailData.innovativeCompanyInd,
    [FieldsValueCode.LEGAL_STATUS_CODE]: detailData.legalStatusCode,
    [FieldsValueCode.LEGAL_STATUS_TITLE]: detailData.legalStatusTitle,
    [FieldsValueCode.LOAN_AMOUNT]: detailData.loanAmount,
    [FieldsValueCode.LOAN_DURATION]: detailData.loanDuration,
    [FieldsValueCode.LOAN_END_DATE]: detailData.loanEndDate,
    [FieldsValueCode.CONTRACT_REFERENCE]: detailData.contractReference,
    [FieldsValueCode.LOAN_RELEASING_DATE]: detailData.loanReleasingDate,
    [FieldsValueCode.NAF_CODE]: detailData.nafCode,
    [FieldsValueCode.OUTSTANDING_CAPITAL]: detailData.outstandingCapital,
    [FieldsValueCode.PARTNER_CUSTOMER_NUMBER]: detailData.partnerCustomerNumber,
    [FieldsValueCode.SALARY_MASS]: detailData.payroll,
    [FieldsValueCode.PORTION]: detailData.portion,
    [FieldsValueCode.REVENUES]: detailData.revenue,
    [FieldsValueCode.SAMPLE_CONTRACT_IND]: detailData.sampleContractInd,
    [FieldsValueCode.SIREN_NUMBER]: detailData.sirenNumber.toString(),
    [FieldsValueCode.PRODUCT]: otherData?.product,
    [FieldsValueCode.FEES_RATE_1_24]: commissionSlices?.[0]?.tamorCommission,
    [FieldsValueCode.FEES_RATE_25_60]: commissionSlices?.[1]?.tamorCommission,
  };
};

export const toContractObject = (data: ContractDetailsDTO): ContractObject => {
  return {
    details: toContractDetails(data.details),
    otherContracts: data.otherContracts || [],
    commissions: data.commissions || [],
    schedules: data.schedules || [],
  };
};

export const toClaimCards = (data: OtherClaim[]): ContractAssociatedClaim[] => {
  return data.map((claim) => ({
    id: claim.id,
    bankName: claim.bankName,
    companyName: claim.companyName,
    paymentTypeVp:
      claim.claimType === ClaimType.RESTRUC ?
        PaymentCodeTypesLabelTranslation[claim.paymentTypeVp as PaymentCodeTypes]:
        claim.paymentCodeType === PaymentCodeTypes.PROVISIONAL?
          PaymentCodeTypesLabelTranslation[claim.paymentCodeType] : "",
    paymentTypeVf:
      claim.claimType === ClaimType.RESTRUC ?
        PaymentCodeTypesLabelTranslation[claim.paymentTypeVf as PaymentCodeTypes]:
        claim.paymentCodeType === PaymentCodeTypes.FINAL?
          PaymentCodeTypesLabelTranslation[claim.paymentCodeType] : "",
    creditEventLabel: claim.creditEventLabel,
    status: claim.claimStatus,
    statusLabel: ContractClaimStatusTranslation[claim.claimStatus],
    siren: claim.siren,
    loanAmount: claim.loanAmount,
    claimStatus: claim.claimStatus,
    bankCompensatedAmount: claim.bankCompensatedAmount,
    claimCreationDate: claim.claimCreationDate,
    claimCreationDateInBRP: claim.claimCreationDateInBRP,
    claimType: claim.claimType,
    lastModifiedDate: claim.lastModifiedDate,
    lastModifiedBy: claim.lastModifiedBy,
    lastModificationDateByPartner: claim.lastModificationDateByPartner,
    motifRejet: claim.motifRejet,
    libellMotifRejet: claim.libellMotifRejet,
    motif: claim.motif,
    subMotif: claim.subMotif,
    bankCode: claim.bankCode,
    managementEventCode: claim.managementEventCode
  }));
};

export interface ClaimsContrat extends ClaimExcerpt{
  lastModifiedDate: Date;
  lastModifiedBy: string;
  lastModificationDateByPartner: Date;
  motifRejet: string;
  libellMotifRejet: string;
}
