<div class="table-detail-schedule ">
  <p-table [columns]="dataContext.columns" [value]="dataContext.data"
           styleClass="p-datatable-striped p-datatable-lg">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let column of columns">
          {{ column.title | transloco}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        <ng-container  *ngFor="let dataKey of dataContext.dataKeys" [ngSwitch]="dataKey.type">
          <td *ngSwitchCase="detailDataType.CURRENCY"> {{ data[dataKey.key] | translocoCurrency }} </td>
          <td *ngSwitchCase="detailDataType.DATE"> {{ data[dataKey.key] | translocoDate }} </td>
          <td *ngSwitchDefault> {{ data[dataKey.key] }} </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>
