import {Component, Input, OnDestroy} from '@angular/core';
import {NavigationError, Router} from '@angular/router';
import {TranslocoService} from '@ngneat/transloco';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {DeclarationHeaderDto, DeclarationType} from "@app/shared/types/declaration";
import {ContractDeclaratoinStatusTranslation, ContratDeclarationTypeTranslation} from "@app/contracts/types/contract";
import {ContractService} from "@app/contracts/services/contract.service";
import {ModalService} from "@app/shared/components/modals/services/modal.service";
import {PAGE_URL} from "@app/app.constants";
/*
import {
  InformationModalComponent
} from "@app/shared/components/modals/components/information-modal/information-modal.component";
*/

@Component({
  selector: 'app-declaration-card',
  templateUrl: './declaration-card.component.html',
  styleUrls: ['./declaration-card.component.scss'],
})
export class DeclarationCardComponent implements OnDestroy {
  declarationDetailRoute!: string;
  declaration!: DeclarationHeaderDto;
  loading = false;
  declarationType = DeclarationType;
  contractDeclaratoinStatusTranslation = ContractDeclaratoinStatusTranslation;
  contratDeclarationTypeTranslation = ContratDeclarationTypeTranslation


  @Input() set declarationHeader(value: DeclarationHeaderDto) {
    this.declaration = value
    this.declarationDetailRoute = `/${PAGE_URL.DECLARATION}/${value.iziDeclarationId}/${value.declarationType}`;
  }

  onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private translocoService: TranslocoService,
    private contractService: ContractService,
    private modalService: ModalService
  ) {
    this.router.events
      .pipe(
        takeUntil(this.onDestroy$),
        filter((event) => event instanceof NavigationError)
      )
      .subscribe(() => {
        this.loading = false;
      });
  }
/*
  goToDeclarationDetails(): void {
    this.loading = true;
    this.contractService.getDeclerationDetail(
      this.declaration.iziDeclarationId,
      this.declaration.declarationType.toString()
    )
      .subscribe(declaration => {
        this.loading = false;
        this.modalService.open(InformationModalComponent, {
          title: "\"🎉 Détails de la Déclaration 🎉" + "\n"
            + "🚀 Bientôt disponibles ! 🚀\"",
          validation: "Ok",
          messages: [
            {
              title: "📄 -- Declaration --",
              message: "\n" +
                "Ecretement" + "\n" +
                "Reference Credit : " + declaration.declarationData.customerAgreementIdentifier + "\n" +
                "Organisme Financiere: " + declaration.declarationData.nationalBankName + "\n" +
                "Montant de crédit: " + declaration.declarationData.loanAmount + "\n" +
                "Date de soumission de la déclaration: " + declaration.declarationData.sendingDate + "\n"
            },
            {
              title: "📄 -- Entreprise et Pret -- ",
              message: "\n" +
                "Raison Social: " + declaration.declarationData.payeeLegalName + "\n" +
                "Siren: " + declaration.declarationData.siren + "\n" +
                "Etablissement intervenant: " + declaration.declarationData.nationalBankName + "\n" +
                "Réference crédit: " + declaration.declarationData.customerAgreementIdentifier + "\n"
            },
            {
              title: "📄 -- Restructuration Contentieuse --",
              message: "\n" +
                "Mode d'amortissement: " + declaration.additionalDeclarationData.operationalRangeAmortizationProfileRequestedLable + "\n" +
                "Periodicité: " + declaration.additionalDeclarationData.paymentDueFrequencyRequestedLabel + "\n" +
                "Date de la 1er échéance: " + declaration.additionalDeclarationData.effectiveReschedulingDate + "\n" +
                "Nouveau taux d'interet: " + declaration.additionalDeclarationData.interestRateRequested + "\n"
            },
            {
              title: "📄 -- Assiete de garantie ecretement --",
              message: "\n" +
                "Montant de la créance globale: " + declaration.declarationData.totalAmountOfRestructuredDebt + "\n" +
                "Montant du versement provisionnel: " + declaration.declarationData.amountsOfProvisionalPayments + "\n" +
                "Montant cumulé des récupérations: " + declaration.declarationData.amountsOfProvisionalPayments + "\n" +
                "Somme des versements effectués à l'Etat: " + declaration.declarationData.totalOfAmountsRepaidToTheState + "\n" +
                "Montant à reverser a l'Etat: " +  declaration.declarationData.amountToBeRepaidToTheState + "\n"
            },
          ]
        })
      });
  }
  */

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  goToDeclarationDetails(): void {
    this.router.navigate([this.declarationDetailRoute]);
  }
}
