<ng-container *ngIf="detailsList && detailsList[0]">

  <ng-container *ngIf="schedule && schedule[0]; else notTableDetail">
    <ng-container *ngIf="editionMode">
      <app-details-validation
        [detailItem]="editableDetails[0]"
        [status]="status"
        [updateFieldValidation]="updateFieldValidation"
        (hideActionButtonsEmitter)="hideActionButtonsEmitter.emit($event)"
        (updateDetailReview)="updateDetailReview($event)"
      ></app-details-validation>

      <div class="accordion-schedule">
        <p-accordion>
          <p-accordionTab header="{{ accordionHeader() | transloco }}"
                          [(selected)]="scheduleToggle">
            <app-details-schedule [detailsSchedule]="schedule">
            </app-details-schedule>
          </p-accordionTab>
        </p-accordion>
      </div>
    </ng-container>

    <app-details-schedule *ngIf="!editionMode" [detailsSchedule]="schedule">
    </app-details-schedule>
  </ng-container>

  <ng-template #notTableDetail>
    <app-grid-details-list
      *ngIf="!editionMode; else editableDetailsList"
      [gridDetailsListItems]="detailsList"
    >
    </app-grid-details-list>

    <ng-template #editableDetailsList>
      <app-grid-details-list
        *ngIf="nonEditableDetails.length"
        [gridDetailsListItems]="nonEditableDetails"
        [separateSection]="false"
      ></app-grid-details-list>

      <ng-container *ngIf="editableDetails.length" >
        <ul>
          <li *ngFor="let detail of editableDetails; let id = index; trackBy:trackByLabel">
            <app-details-validation
              [detailItem]="detail"
              [status]="status"
              [updateFieldValidation]="updateFieldValidation"
              (deleteDetailReviewEvent)="deleteDetailReview(detail)"
              (hideActionButtonsEmitter)="hideActionButtonsEmitter.emit($event)"
              (updateDetailReview)="updateDetailReview($event)"
            ></app-details-validation>
          </li>
        </ul>
      </ng-container>
    </ng-template>
  </ng-template>
</ng-container>

<table *ngIf="histories && histories[0]">
  <thead>
  <tr>
    <th *ngFor="let header of historyHeader">{{ header | transloco}}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let claimHistory of histories">
    <td>{{ claimHistory.date | translocoDate }}</td>
    <td class="td-alignment">{{ claimHistory.userAction }}</td>
    <td class="td-alignment">{{ claimHistory.rejectedFieldLabel }}</td>
    <td class="td-alignment">{{ claimHistory.comment }}</td>
    <td class="td-alignment">{{ claimHistory.user }}</td>
  </tr>
  </tbody>
</table>
