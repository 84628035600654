// eslint-disable-next-line no-shadow
export enum Status {
  TO_BE_ASSIGNED = 'TO_BE_ASSIGNED', // S'affecter la demande.
  TO_BE_CHECKED = 'TO_BE_CHECKED', // Valider le controle pour visa. if there is no retours.
  // enable editing / if in edit mode -> renvoyer les retours au partenaire bancaire
  TO_BE_SIGNED = 'TO_BE_SIGNED', // A viser --> Viser la demande. //
  SIGNED = 'SIGNED', // Visé --> // pas de bouton.
  DRAFT = 'DRAFT',
  RECEIVED = 'RECEIVED',
  TO_BE_FIXED = 'TO_BE_FIXED',
  CANCELLED = 'CANCELLED',
  TO_BE_COMPLETED = 'TO_BE_COMPLETED',
  REJECTED = 'REJECTED',
  TO_BE_PAID = 'TO_BE_PAID',
  PAID = 'PAID',
  CANCELLED_TECH = 'CANCELLED_TECH',
  CANCELLED_BY_MANAGER = 'CANCELLED_BY_MANAGER'
}
