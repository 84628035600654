import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {toContractObject} from '@app/contracts/helpers/contract-details.helper';
import {ContractExcerpt} from '@app/contracts/types/contract-excerpt';
import {OtherClaim} from '@app/dashboard/types/claims';
import {DateObjectItems} from '@app/shared/types/contract';
import {ContractDetailsDTO, ContractObject, SchedulesCommissionsObject,} from '@app/shared/types/details';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {map, pluck} from 'rxjs/operators';
import {AuditTrailYear} from "@app/contracts/types/audit-trail";
import { DeclarationHeaderDto} from "@app/shared/types/declaration";

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  baseUrl = `${environment.apiBase}/api/v1/internal/contracts`;
  constructor(private httpClient: HttpClient) {}

  getContractsBySiren(siren: string): Observable<ContractExcerpt[]> {
    const sirenParam = `?siren=${siren}`;
    const url = `${this.baseUrl}${sirenParam}`;
    return this.httpClient.get<ContractExcerpt[]>(url).pipe(pluck('contracts'));
  }

  getContractObject(
    bankCode: string,
    contractReference: string,
    financingContractId?: string,
    warrantyContractId?: string
  ): Observable<ContractObject> {
    const url = `${this.baseUrl}/${bankCode}`;

    let params = new HttpParams().set('contractReference', contractReference);
    if (financingContractId) {
      params = params.set('financingContractId', financingContractId);
    }
    if (warrantyContractId) {
      params = params.set('warrantyContractId', warrantyContractId);
    }
    return this.httpClient
      .get<ContractDetailsDTO>(url, { params })
      .pipe(map(toContractObject));
  }

  getSchedulesCommissions(
    bankCode: string,
    contractId: string,
    date?: string,
    maxLines?: number
  ): Observable<SchedulesCommissionsObject> {

    const url = `${this.baseUrl}/schedules-commissions/${bankCode}`;
    const param = new HttpParams(
      date ? { fromObject: { date } } : undefined
    ).set('financingContractId', encodeURIComponent(contractId))
    .set('maxLines', maxLines ? maxLines.toString() : '15');
    return this.httpClient.get<SchedulesCommissionsObject>(url, {
      params: param
    });
  }

  getSchedulesCommissionsVersions(
    bankCode: string,
    contractId: string
  ): Observable<string[]> {
    const url = `${this.baseUrl.replace(
      'contracts',
      'schedules'
    )}/versions/${bankCode}/${contractId}`;
    return this.httpClient.get<DateObjectItems>(url).pipe(
      pluck('items'),
      map((dates) => dates.reverse())
    );
  }

  getContractAssociatedClaims(
    bankCode: string,
    contractReference: string,
    financingContractId?: string,
    warrantyContractId?: string
  ): Observable<OtherClaim[]> {
    const url = `${this.baseUrl}/${bankCode}/claims`;

    let params = new HttpParams().set('contractReference', contractReference);
    if (financingContractId) {
      params = params.set('financingContractId', financingContractId);
    }
    if (warrantyContractId) {
      params = params.set('warrantyContractId', warrantyContractId);
    }
    return this.httpClient.get<OtherClaim[]>(url, { params }).pipe(pluck('items'));
  }

  getContractAuditTrail(codeBank: string, contractReference: string): Observable<AuditTrailYear[]> {
    const url = `${this.baseUrl}/${codeBank}/audit`;
    const params = new HttpParams().set('contractReference', contractReference);
    return this.httpClient.get<AuditTrailYear[]>(url, { params }).pipe(pluck('items'));
  }

  getContractAssociatedDeclarations(
    bankCode: string,
    contractReference: string,
    financingContractId?: string,
    warrantyContractId?: string
  ): Observable<DeclarationHeaderDto[]> {
    const url = `${this.baseUrl}/${bankCode}/declarations`;

    let params = new HttpParams().set('contractReference', contractReference);
    if (financingContractId) {
      params = params.set('financingContractId', financingContractId);
    }
    if (warrantyContractId) {
      params = params.set('warrantyContractId', warrantyContractId);
    }
    return this.httpClient.get<DeclarationHeaderDto[]>(url, { params }).pipe(pluck('items'));
  }

  getMoreScheduleLines(
    bankCode: string,
    contractId: string,
    maxLine: number
  ): Observable<SchedulesCommissionsObject> {
    const url = `${this.baseUrl}/last-commissions-schedules/${bankCode}`;
    const param = new HttpParams()
      .set('financingContractId', encodeURIComponent(contractId))
      .set('maxLine', maxLine);

    return this.httpClient.get<SchedulesCommissionsObject>(url, {
      params: param
    });
  }
}
