<bpi-styled-modal-dialog
  id="confirm-dialog"
  [title]="confirmModalInfo.title"
  [image]="'assets/images/feedback-confirmation-modal.svg'"
  [isOpen]="modalInstance.isOpen"
  [closeable]="true"
  [primaryButton]="confirmModalInfo.validation"
  [secondaryButton]="confirmModalInfo.cancellation ? confirmModalInfo.cancellation : ''"
  (onConfirm)="close(inputValue())"
  (onSecondary)="close()"
  (onClose)="close()"
>
  <div class="modal-content-message" *ngFor="let message of confirmModalInfo.messages">
    <p> {{ message.title }} {{ message.message }}</p>
  </div>

  <div *ngIf="confirmModalInfo.showFileUpload"
    [ngStyle]="{ display: 'flex', 'flex-direction': 'column', gap: '0.5rem' }">
    <h4 [ngStyle]="{ display: 'flex', 'align-items': 'start', gap: '0.2rem' }">
      {{ 'claims.modals.confirm-feedback.file-attachment' | transloco }} <span [ngStyle]="{ color: 'red' }"> * </span>
      <span class="file-type"> {{'claims.modals.confirm-feedback.file-type' | transloco}}</span>
    </h4>

    <bpi-empty-uploaded-file
      [label]="inputLabel"
      [loading]="false"
      [accept]="['.jpg', '.png', '.pdf']"
      (onFileUpload)="uploadFile($event)"
    ></bpi-empty-uploaded-file>

  </div>

  <div *ngIf="confirmModalInfo.showComment"
       [ngStyle]="{ display: 'flex', 'flex-direction': 'column', gap: '0.5rem' }">
    <h4 [ngStyle]="{ display: 'flex', 'align-items': 'start' }"> Commentaire </h4>

    <bpi-styled-long-text-input [inputFormControl]="comment"></bpi-styled-long-text-input>
  </div>
</bpi-styled-modal-dialog>
