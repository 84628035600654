// eslint-disable-next-line no-shadow
import {ClaimStatus} from "@app/dashboard/types/claims";
import {DeclarationType} from "@app/shared/types/declaration";
import {Status} from "@app/shared/types/status";

export const ContractClaimStatusTranslation: { [type in ClaimStatus]: string } = {
  [ClaimStatus.TO_BE_ASSIGNED]: 'dashboard.claim-card.claim-status-label.to_be_assigned',
  [ClaimStatus.TO_BE_CHECKED]: 'dashboard.claim-card.claim-status-label.to_be_checked',
  [ClaimStatus.TO_BE_SIGNED]: 'dashboard.claim-status.to-be-signed',
  [ClaimStatus.SIGNED]: 'dashboard.claim-status.signed',
  [ClaimStatus.TO_BE_COMPLETED]: 'dashboard.claim-card.claim-status-label.to_be_completed',
  [ClaimStatus.REJECTED]: 'dashboard.claim-card.claim-status-label.rejected',
  [ClaimStatus.TO_BE_PAID]: 'dashboard.claim-card.claim-status-label.to_be_paid',
  [ClaimStatus.PAID]: 'dashboard.claim-card.claim-status-label.paid',
  [ClaimStatus.DRAFT]: '',
  [ClaimStatus.RECEIVED]: '',
  [ClaimStatus.TO_BE_FIXED]: '',
  [ClaimStatus.CANCELLED]: '',
  [ClaimStatus.CANCELLED_TECH]: 'dashboard.claim-card.claim-status-label.cancelled-tech',
  [ClaimStatus.CANCELLED_BY_MANAGER]: 'dashboard.claim-card.claim-status-label.cancelled-manager'
};

export const ContractDeclaratoinStatusTranslation: { [type in Status]: string } = {

  [Status.SIGNED]: 'contracts.declaration-status.signed',
  [Status.TO_BE_PAID]: 'contracts.declaration-status.to_be_paid',
  [Status.PAID]: 'contracts.declaration-status.paid',

  [Status.TO_BE_ASSIGNED]: '',
  [Status.TO_BE_CHECKED]: '',
  [Status.TO_BE_SIGNED]: '',
  [Status.TO_BE_COMPLETED]: '',
  [Status.REJECTED]: '',
  [Status.DRAFT]: '',
  [Status.RECEIVED]: '',
  [Status.TO_BE_FIXED]: '',
  [Status.CANCELLED]: '',
  [Status.CANCELLED_TECH]: '',
  [Status.CANCELLED_BY_MANAGER]: ''
};

export const ContratDeclarationTypeTranslation: { [type in DeclarationType]: string } = {
  [DeclarationType.ECRETEMENT]: 'contracts.declaration-type.ecretement',
  [DeclarationType.STC]: 'contracts.declaration-type.stc',
};
