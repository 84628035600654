<header class="header" [ngClass]="{'header--fixed': fixed}" (click)="shouldShowLights = !shouldShowLights">
  <a id="header__logo" class="header__logo" name="home"  (click)="goToMainPage()">
    <img [src]="logo" />
  </a>

  <nav class="header-buttons" *ngIf="hasButtons">
    <ul class="header-buttons__container">
      <ng-container *ngFor="let button of navButtons; trackBy: trackByIndex">
        <li class="header-button" *canVisualize="button.roles!">
          <a [attr.id]="button.label" class="header-button-content" [routerLink]="button.link">
            <mat-icon> {{ button.icon }} </mat-icon>
            {{ button.label | transloco }}
          </a>
        </li>
      </ng-container>

      <li class="header-button">
        <mat-menu class="header-button__menu" #menu="matMenu" xPosition="before">
          <button id="administration" *ngIf="isAdmin" (click)="redirectToAdminPage()" mat-menu-item class="header-buttons__disconnect">
            <mat-icon>build</mat-icon>
            <span>{{ 'header.administration' | transloco }}</span>
          </button>
          <button id="disconnect" (click)="logout()" mat-menu-item class="header-buttons__disconnect">
            <mat-icon>power_settings_new</mat-icon>
            <span>{{ 'header.disconnect' | transloco }}</span>
          </button>
        </mat-menu>

        <button id="header-buttons__username" [matMenuTriggerFor]="menu" class="header-button--row header-buttons__username">
          <span>{{ username }}</span>
          <mat-icon svgIcon="izi_account"></mat-icon>
        </button>
      </li>
    </ul>
  </nav>
</header>
<!--<app-christmas-lights  *ngIf="canShowLights && shouldShowLights"></app-christmas-lights>-->
