import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {DetailReview, DetailReviewUpdate, fieldRestruc} from '@app/claims/types/review';
import { AccountService } from '@app/core/auth/account.service';
import { DetailsTypesService } from '@app/shared/services/details-types/details-types.service';
import { ApiResponse } from '@app/shared/types/api-response';
import {DetailItem, SetManagerCommentFn} from '@app/shared/types/details';
import { validatePastDate } from '@bpi/shared-components/dynamic-form';
import { Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { FieldValidation } from "@app/claims/types/action-button";
import {FieldsValueCode} from "@app/shared/types/field-code";
import {Status} from "@app/shared/types/status";

@Component({
  selector: 'app-details-validation',
  templateUrl: './details-validation.component.html',
  styleUrls: ['./details-validation.component.scss'],
})
export class DetailsValidationComponent implements OnInit, OnDestroy {
  @Input() detailItem!: DetailItem;
  @Input() isEditable = true;
  @Input() status!: Status;
  @Input() setManagerComment!: SetManagerCommentFn;

  @ViewChild('focusOnTextArea')
  helpShown = false;
  formGroup!: FormGroup;
  rejectDisabled!: boolean;
  onDestroy$ = new Subject();
  isNewAttachment!: boolean;
  validFeedback!: boolean | null;
  @Input() set updateFieldValidation(values:{ validFeedback: boolean, rejectDisabled: boolean }) {
    if (!values) {
      return;
    }

    if (!fieldRestruc.includes(this.detailItem?.fieldCode as FieldsValueCode)) {
      return;
    }

    this.validFeedback = values.validFeedback;
    const comment = this.detailItem?.feedback?.comments;
    if (comment && !comment[0]) {
      this.rejectDisabled = values.rejectDisabled;
    }
  }

  @Output()
  hideActionButtonsEmitter: EventEmitter<FieldValidation> = new EventEmitter<FieldValidation>();

  @Output() deleteDetailReviewEvent: EventEmitter<DetailReview> = new EventEmitter<DetailReview>();

  @Output()
  updateDetailReview: EventEmitter<{ id: string, detailReview: DetailReview }>
    = new EventEmitter<{ id: string, detailReview: DetailReview }>();

  get isTobeChecked(): boolean {
    return this.status === Status.TO_BE_CHECKED;
  }

  get isValid(): FormControl {
    return this.formGroup.get('validated') as FormControl;
  }

  get comment(): FormControl {
    return this.formGroup.get('comment') as FormControl;
  }

  constructor(
    public typeService: DetailsTypesService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.isNewAttachment = this.detailItem.missing as boolean;
    this.rejectDisabled = true;
    this.validFeedback = this.detailItem.feedback?.isValid as boolean;
    const detailValue = this.typeService.isDocumentDetail(this.detailItem)
      ? this.detailItem.value.documentName
      : this.detailItem.value;

    // if item already has comments, we dont need to disable the reject button
    if (this.detailItem.feedback?.comments?.length) {
      this.rejectDisabled = false;
    }

    this.formGroup = this.formBuilder.group({
      validated: new FormControl(this.detailItem.feedback?.isValid),
      detailValue: new FormControl(detailValue),
      comment: new FormControl(''),
    });

    if (this.typeService.isDateDetail(this.detailItem)) {
      (this.formGroup.get('detailValue') as FormControl).setValidators(
        validatePastDate()
      );
    }

    if (!this.isEditable) {
      (this.formGroup.get('comment') as FormControl).disable();
      (this.formGroup.get('detailValue') as FormControl).disable();
    }

    // enable the reject button if the user has entered a comment.
    // disable it if the comment is empty
    this.comment.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((comment: string) => {
        if (comment && comment.trim().length > 0) {
          this.rejectDisabled = false;
          this.validFeedback = null;
          this.hideActionButtonsEmitter.emit({
            fieldCode: this.detailItem.fieldCode,
            validFeedback: null,
            rejectDisabled: false
          });
        } else {
          this.rejectDisabled = true;
        }
      });

    this.isValid.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.updateFeedback(this.formGroup.getRawValue()));

    const canAddComment =
      this.accountService.isManager &&
      this.status === Status.TO_BE_SIGNED;

    this.comment.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        distinctUntilChanged(),
        debounceTime(1000),
        switchMap(() =>
          canAddComment
            ? this.updateManagerFeedback(this.formGroup.getRawValue())
            : of()
        )
      )
      .subscribe();
  }

  updateFeedback(update: DetailReviewUpdate): void {
    const { id } = this.route.snapshot.params;
    const detailReview: DetailReview = {
      fieldCode: this.detailItem.fieldCode,
      missing: this.detailItem.missing,
      taskId: id,
      validated: update.validated,
      comment: update.comment,
    };

    // in order to update the value of the actual reference
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.detailItem.feedback?.isValid = update.validated;
    this.hideActionButtonsEmitter.emit({
      fieldCode: this.detailItem.fieldCode,
      validFeedback: this.validFeedback,
      rejectDisabled: this.rejectDisabled
    });
    this.updateDetailReview.emit({ id, detailReview });
  }

  updateManagerFeedback(update: DetailReviewUpdate): Observable<ApiResponse> {
    const { id } = this.route.snapshot.params;
    const detailReview: DetailReview = {
      fieldCode: this.detailItem.fieldCode,
      taskId: id,
      validated: update.validated,
      comment: update.comment,
    };

    return this.setManagerComment(id, detailReview);
  }


  onValidationChange(isValid: boolean | null) {
    this.validFeedback = isValid;
    this.formGroup.controls.validated.patchValue(isValid);
  }

  deleteReview() {
    this.detailItem.missing = false;
    this.validFeedback = null;
    this.formGroup.controls.validated.patchValue(null);
    this.deleteDetailReviewEvent.emit();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
