import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { trackByIndex } from '@app/shared/helpers/object.helpers';
import { Comment } from '@app/shared/types/details';

/**
 * @debt: DUPLICATED_INFORMATION "maxencel:Remove isValid use since it will always be false"
 */
@Component({
  selector: 'app-details-feedback',
  templateUrl: './details-feedback.component.html',
  styleUrls: ['./details-feedback.component.scss'],
})
export class DetailsFeedbackComponent {
  @Input() isValid?: boolean | null;
  @Input() formGroup!: FormGroup;
  @Input() feedbackHistory?: Comment[];
  @Input() isNewAttachment!: boolean;
  @Input() disabled = false;

  @ViewChild('focusOnTextArea') searchElement!: ElementRef<HTMLTextAreaElement>;
  trackByIndex = trackByIndex;
  get commentFormControl(): FormControl {
    return this.formGroup.get('comment') as FormControl;
  }

  setFocusOnTextArea() {
    this.searchElement.nativeElement.focus();
  }
}
