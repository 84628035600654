import {Component, Input, OnInit} from '@angular/core';
import {DataContext} from "@app/shared/types/table";
import {Schedule} from "@app/shared/types/contract";
import {DetailDataType} from "@app/shared/types/text-display-type";

@Component({
  selector: 'app-details-schedule',
  templateUrl: './details-schedule.component.html',
  styleUrls: ['./details-schedule.component.scss']
})
export class DetailsScheduleComponent implements OnInit {
  @Input() detailsSchedule!: Schedule[];
  dataContext: DataContext<Schedule>;
  detailDataType = DetailDataType;

  constructor() {
    this.dataContext = {
      data: [],
      columns : [
        { title : "details.shared.schedules.indId" },
        { title : "details.shared.schedules.startDate" },
        { title : "details.shared.schedules.endDate" },
        { title : "details.shared.schedules.outstandingAmount" },
        { title : "details.shared.schedules.scheduleAmount" },
        { title : "details.shared.schedules.riskOutstandingAmount" },
        { title : "details.shared.schedules.interestAmount" },
      ],
      dataKeys: [
        { key: "indId" },
        { key: "startDate", type: DetailDataType.DATE  },
        { key: "endDate", type: DetailDataType.DATE },
        { key: "outstandingAmount", type: DetailDataType.CURRENCY  },
        { key: "scheduleAmount", type: DetailDataType.CURRENCY },
        { key: "riskOutstandingAmount", type: DetailDataType.CURRENCY  },
        { key: "interestAmount", type: DetailDataType.CURRENCY  },
      ],
      totalRecords: 0
    };
  }

  ngOnInit(): void {
    this.dataContext.data = this.detailsSchedule;
  }
}
