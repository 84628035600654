import { CancelClaimStatus } from "@app/claims/types/claimCancel";
import { ClaimStatus, ManagementEventCode, SamplingStatus } from '@app/dashboard/types/claims';
import {ClaimType} from "@app/shared/types/claim-types";

import {ContractDTO, OtherContractDTO, Schedule} from './contract';
import {CREDIT_EVENTS, PaymentCodeTypes} from './details';
import {GedAttachmentCodes} from './field-code';


export interface ClaimDTO {
  claimData: {
    bank: {
      bankCode: string;
      bankName: string;
    };
    balanceSheet: number;
    remainingCapital: number;
    turnover?: number;
    motifCreditEventLabel: string;
    creditEventSubMotifLabel: string;
    realClientLoan: number;
    paymentCodeType: PaymentCodeTypes;
    claimCreationDateInBRP: string;
    financingContracts: Array<{
      financingContractDate: string;
      financingContractid: string;
      loanInitalAmount: number;
      creditReference: string;
    }>;
    warrantyContract: {
      warrantyContractDate: string;
      warrantyContractId: string;
      samplingStatus: string;
      feesRate: number;
    };
    finance: {
      siren: string;
      companyName: string;
    };
    claimId: string;
    workforce: number;
    amountAccess: number;
    compensableAmount: number;
    compensatedAmount: number;
    certificateDate?: string;
    compensableAmountDate?: string;
    insufficientAssetsClosingDate?: string;
    interestAmount: number;
    attachments: GedAttachmentUrlObject[];
    claimStatus: ClaimStatus;
    recoveryTotal: number;
    provisionalPaymentTotal: number;
    companyRevenue: number;
    companyEmployeesNumber: number;
    lastClaimUpdateDate: string;
    cancelClaimStatus: CancelClaimStatus;

    user: {
      userId: number;
      // TODO: API_VALUE missing. Temporary matricule
      mat?: string;
    };
    // Restructuration
    idBrp: number;
    installmentPaymentRequest: boolean;
    organisationRevenueRequested: number;
    companyEmployeesNumberRequested: number;
    payroll: number;
    payrollRequested: number;
    loanAmount: number;
    operationalRangeAmortizationProfileRequested: string;
    operationalRangeAmortizationProfileRequestedLable: string;
    outstandingPrincipalAtRearrangement: number;
    outstandingPrincipalAtRearrangementDateRequested: number;
    effectiveReschedulingDate: string;
    paymentDueFrequencyRequested: string;
    paymentDueFrequencyRequestedLabel: string;
    suspendedPaymentDueNumber: number;
    interestRateRequested: number;
    secondNamePersonBorrower: string;
    firstNamePersonBorrower: string;
    innovativeCompany: boolean;
    customerAgreementReference: string;
    product: string;
    creditReleaseDate: string;
    paymentScheduleTerminationDate: string;
    creditAmount: number;
    loanTerm: number;
    paymentDueFrequency: number;
    dampingMode: string;
    commissionRate: number;
    interveningInstitution: string;
    settlementAmount: number;
    requestCreationDate: string;
    createdBy: string;
    adresseEntreprise: string;
    codePostaleEntreprise: string;
    villeEntreprise: string;
    workforceRequested: number;
    ctxWithForfeited: boolean;
    guaranteeActivationDuePayment: number;
    forfeitedOutstandingPrincipal: number;
    amountFeesAccess: number;
    amountToKeep: number;
    remainProvisionalPaymentTotal: number;
    definitiveCompensationStatementDate: string;
    amountVpRestruct: number;
    amountCompensationVfForfeited: number;
    rearrangementFeesAmount: number;

    schedules: Schedule[];
  };
  provisionPayments: Array<{
    order: number;
    montantProvision: number;
  }>;
  contractDetails: ContractDTO;
  otherContracts: OtherContractDTO[];
  lawyerDetails: {
    selfcareId?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
  };
  additionalClaimData: {
    claimCreationDate: string;
    claimCompensationEndDate: string;
    creditEventDate: string;
    creditEventLabel: CREDIT_EVENTS;
    samplingStatus: SamplingStatus;
    managementEventCode: ManagementEventCode;
  };
  partnerDetail: {
    email: string;
    firstName: string;
    lastName: string;
  };
  claimType: ClaimType;
}

export interface GedAttachmentUrlObject {
  attachmentCode: GedAttachmentCodes;
  attachmentId: string;
  domain: string;
  type?: string;
  gedUrl?: string;
}

// eslint-disable-next-line no-shadow
export enum AuditFieldCode {
  REJETCTROL = 'REJETCTROL',
  ANNUDEMIND = 'ANNUDEMIND',
  REFCTRLJUR = 'REFCTRLJUR',
  ACCCTRLJUR = 'ACCCTRLJUR',
  VALIDCTROL = 'VALIDCTROL',
  REJETVISA = 'REJETVISA',
  VISAMNGER = 'VISAMNGER'
}
