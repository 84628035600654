import { Component, Input, OnDestroy } from '@angular/core';
import { NavigationError, Router } from '@angular/router';
import { PAGE_URL } from '@app/app.constants';
import { ContractClaimStatusTranslation } from "@app/contracts/types/contract";
import { ClaimExcerpt, ClaimStatus, managementEventCodesForForfeiture } from '@app/dashboard/types/claims';
import { ALERT_TYPES, AlertType, AlertTypeColors, } from '@app/shared/types/alert';
import { ClaimType } from "@app/shared/types/claim-types";
import { PaymentCodeTypes, PaymentCodeTypesLabelTranslation, } from '@app/shared/types/details';
import { DateUtils } from "@app/shared/utils/date-utils";
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-claim-card',
  templateUrl: './claim-card.component.html',
  styleUrls: ['./claim-card.component.scss'],
})
export class ClaimCardComponent implements OnDestroy {
  claimDetailRoute!: string;
  claim!: ClaimExcerpt;
  loading = false;
  claimType = ClaimType;
  managementEventCodesGreenColor = managementEventCodesForForfeiture;

  @Input() setAlert = true;
  @Input() hasRedirect = true;
  @Input() showCompletenessInfo = true;
  @Input() set claimDetails(value: ClaimExcerpt) {
    this.claim = { ...value };

    if (this.setAlert) {
      this.claim = {
        ...value,
        ...(value.compensationEndDate
          ? {
              claimAlert: this.setClaimAlert(value.compensationEndDate),
              remainingDays: this.getClaimsDaysLeft(value.compensationEndDate),
            }
          : {}),
        paymentTypeVp:
          PaymentCodeTypesLabelTranslation[value.paymentTypeVp as PaymentCodeTypes],
        paymentTypeVf:
          PaymentCodeTypesLabelTranslation[value.paymentTypeVf as PaymentCodeTypes],
      };
    }
    this.claimDetailRoute = `/${PAGE_URL.CLAIM}/${value.bankCode}/${value.id}`;
  }

  CLAIM_STATUS = ClaimStatus;
  daysToAdd = -90;
  onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private translocoService: TranslocoService
  ) {
    this.router.events
      .pipe(
        takeUntil(this.onDestroy$),
        filter((event) => event instanceof NavigationError)
      )
      .subscribe(() => {
        this.loading = false;
      });
  }

  setClaimAlert(compensationEndDate: string): AlertTypeColors | undefined {
    const HIGH_ALERT = (value: number) => value <= 29;
    const NO_ALERT = (value: number) => value >= 60;
    const daysLeft = this.getClaimsDaysLeft(compensationEndDate);

    const hasHighAlert = HIGH_ALERT(daysLeft);
    const hasNoAlert = NO_ALERT(daysLeft);

    if (hasNoAlert) {
      return undefined;
    }

    if (hasHighAlert) {
      return ALERT_TYPES[AlertType.RED];
    }

    return ALERT_TYPES[AlertType.ORANGE];
  }

  getClaimsDaysLeft(compensationEndDate: string): number {
    const one_day = 1000 * 60 * 60 * 24;
    const _compensationEndDate = new Date(compensationEndDate);
    const currentDate = new Date();
    const compensationEndDateMillis = _compensationEndDate.getTime();
    const currentDateMillis = currentDate.getTime();
    const diffMillis = compensationEndDateMillis - currentDateMillis;

    return Math.round(diffMillis / one_day);
  }

  getPositiveInteger(int: number): number {
    return Math.abs(int);
  }

  getClaimDaysLeftText(claimDaysLeft: number): string {
    let translatedText = '';
    if (claimDaysLeft >= 0) {
      translatedText = this.translocoService.translate<string>(
        'dashboard.claim-card.remaining-days'
      );
      translatedText = translatedText.replace('$', claimDaysLeft.toString());
    } else {
      translatedText = this.translocoService.translate<string>(
        'dashboard.claim-card.days-passed'
      );
      translatedText = translatedText.replace(
        '$',
        this.getPositiveInteger(claimDaysLeft).toString()
      );
    }
    return translatedText;
  }

  goToClaimDetails(): void {
    if (this.hasRedirect) {
      this.loading = true;
      this.router.navigate([this.claimDetailRoute]);
    }
  }

  showCompensationEndDate(createdDate: string, compensationEndDate: string): boolean {
      return DateUtils.compareDates(new Date(createdDate),
        DateUtils.addDays(new Date(compensationEndDate), this.daysToAdd)) === -1;
  }

  retrieveDays(compensationEndDate: string): Date {
    return DateUtils.addDays(new Date(compensationEndDate), this.daysToAdd);
  }

  isRecentCompleteness(status: ClaimStatus, createdDate: string, compensationEndDate: string) {
    return status === ClaimStatus.TO_BE_CHECKED
    && DateUtils.daysBetweenDates(new Date(), this.retrieveDays(compensationEndDate)) >= 0
    && DateUtils.daysBetweenDates(new Date(), this.retrieveDays(compensationEndDate)) <= 7
    && !DateUtils.isSameDate( DateUtils.addDays(new Date(compensationEndDate), -90), new Date(createdDate))
  }

  getPaymentTypeFormatText(): string {
    if (this.claim) {
      if (this.claim.paymentTypeVf && this.claim.paymentTypeVp) {
        return this.translocoService.translate('dashboard.claim-card.payment') + this.claim.paymentTypeVf + '/'
          + this.claim.paymentTypeVp;
      } else if (this.claim.paymentTypeVf && !this.claim.paymentTypeVp) {
        return this.translocoService.translate('dashboard.claim-card.payment') + this.claim.paymentTypeVf;
      } else if (!this.claim.paymentTypeVf && this.claim.paymentTypeVp) {
        return this.translocoService.translate('dashboard.claim-card.payment') + this.claim.paymentTypeVp;
      }
    }
    return "";
  }

  toStatusLabel() {
    return ContractClaimStatusTranslation[this.claim.status];
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
