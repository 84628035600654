<div
  class="card"
  [ngClass]="{'card-bg-color-picasso': declaration.declarationType === declarationType.STC }"
  (click)="goToDeclarationDetails()"
  tooltipPosition="top"
>

  <div class="card-header">
    <div class="card-header__title">
      <h3>{{ declaration.companyName }}</h3>
    </div>
  </div>

  <div class="card-content">
    <p>{{ contratDeclarationTypeTranslation[declaration.declarationType] | transloco}}</p>
    <p>
      <span class="card-content__label">{{
          "contracts.declaration-card.vp-amount" | transloco
        }}
      </span>
      {{
        declaration.amountsOfProvisionalPayments || declaration.amountsOfRemainingProvisionalPayments
          | translocoCurrency: "symbol":{ minimumFractionDigits: 0 }
      }}
    </p>
    <p *ngIf="declaration.totalOfAmountsRepaidToTheState">
      <span class="card-content__label">{{
          "contracts.declaration-card.total-repaid-amount" | transloco
        }}
      </span>
      {{
        declaration.totalOfAmountsRepaidToTheState
          | translocoCurrency: "symbol":{ minimumFractionDigits: 0 }
      }}
    </p>
    <p>
      <span class="card-content__label">{{
        "contracts.declaration-card.siren" | transloco
      }}</span>
      {{ declaration.siren }}
    </p>
    <p>
      <span class="card-content__label">{{
        "contracts.declaration-card.bank" | transloco
      }}</span>
      {{ declaration.bankName }}
    </p>
    <p>
      <span class="card-content__label">{{
          "contracts.declaration-card.repaid-amount" | transloco
        }}
      </span>
      {{
        declaration.amountToBeRepaidToTheState
          | translocoCurrency: "symbol":{ minimumFractionDigits: 0 }
      }}
    </p>
    <p *ngIf="declaration.yearOfDeclaration">
      <span class="card-content__label">{{
          "contracts.declaration-card.year" | transloco
        }}</span>
      {{ declaration.yearOfDeclaration }}
    </p>
     <p>
        <span class="card-content__label">{{
          "contracts.declaration-card.declaration-status" | transloco
        }}</span>
        {{ contractDeclaratoinStatusTranslation[declaration.declarationStatus] | transloco }}
      </p>
      <p>
          <span class="card-content__label">{{
              "contracts.declaration-card.declaration-sending-date" | transloco
            }}</span>
        {{ declaration.declarationSendingDateInBRP | translocoDate }}
      </p>
    <p *ngIf="declaration.finalSettlementReason">
          <span class="card-content__label">{{
              "contracts.declaration-card.reason" | transloco
            }}</span>
      {{ declaration.finalSettlementReason }}
    </p>
  </div>

  <div class="card-footer">
  </div>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>
