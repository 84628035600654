<div [attr.id]="selectGroupPayment?.groupPaymentId" class="card groups-payment-card" *ngIf="selectGroupPayment" (click)="navigateToDtail()">
  <div class="card-header">
    <h3 class="card-header__title"> {{ selectGroupPayment.compensationStartDate | toMonthDate }}</h3>
    <div class="card-header__icon">
      <mat-icon [ngStyle]="{ 'color': groupPaymentSatusLabel.statusColor }"> check_circle</mat-icon>
    </div>
  </div>

  <div class="card-content groups-payment-card-content">
    <p>
      <span class="card-content__label" [ngSwitch]="selectGroupPayment.typeGroupPayment">
        <ng-container *ngSwitchCase="typeGroupPayment.RBT">
          {{ "grouppayment.card.number-of-refunds" | transloco }}
        </ng-container>
        <ng-container *ngSwitchCase="typeGroupPayment.INDE">
          {{ "grouppayment.card.number-of-compensation" | transloco }}
        </ng-container>
      </span>
      {{ selectGroupPayment.totalLigne }}
    </p>

    <p>
      <span class="card-content__label">
        {{ "grouppayment.card.total-amount" | transloco }}
      </span>
      {{ selectGroupPayment.totalAmount | translocoCurrency }}
    </p>

    <p>
      <ng-container *ngIf="selectGroupPayment.validationDate; else blancSpace">
        <span class="card-content__label">
          {{ "grouppayment.status.validaded-on" | transloco }}
        </span>
        {{ selectGroupPayment.validationDate | date: "dd/MM/yyyy" }}
      </ng-container>
    </p>

    <p>
      <ng-container *ngIf="selectGroupPayment.payedDate && selectGroupPayment.statusGroupPayment === status.PAYE;
                             else blancSpace">
        <span class="card-content__label" [ngSwitch]="selectGroupPayment.typeGroupPayment">
          <ng-container *ngSwitchCase="typeGroupPayment.RBT">
            {{ "grouppayment.status.refund-on" | transloco }}
          </ng-container>
          <ng-container *ngSwitchCase="typeGroupPayment.INDE">
            {{ "grouppayment.status.payed-on" | transloco }}
          </ng-container>
        </span>
        {{ selectGroupPayment.payedDate | date: "dd/MM/yyyy" }}
      </ng-container>
    </p>
  </div>

  <div class="card-footer">
    <div class="groups-payment-card-footer-status">
      <p>
        {{ groupPaymentSatusLabel.statusLable | transloco }}
      </p>
    </div>
  </div>
</div>

<ng-template #blancSpace>
  &nbsp;
</ng-template>
