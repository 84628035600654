import { Component } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ModalBody, Modal } from "@app/shared/components/modals/types/modal.model";

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent extends Modal {
  confirmModalInfo: ModalBody = {
    title: '',
    items: [],
    validation:'',
    cancellation:''
  };

  comment = new FormControl('');
  inputLabel = 'Choisir un fichier';
  fileToUpload = new FormControl(File);

  onInjectInputs(inputs: ModalBody): void {
    if (inputs) {
      this.confirmModalInfo = inputs;
    }

    if (this.confirmModalInfo.comment) {
      this.comment.patchValue(this.confirmModalInfo.comment);
    }
  }

  inputValue() {
    return { comment: this.comment.value, file: this.fileToUpload.value };
  }

  uploadFile(fileList: FileList) {
    if (fileList && fileList.item(0)) {
      this.fileToUpload.patchValue(fileList.item(0));
      this.inputLabel = fileList.item(0)?.name ?? 'Choisir un fichier';
    }
  }
}
