import { Component, EventEmitter, Input, Output } from '@angular/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-details-toggle-buttons',
  templateUrl: './details-toggle-buttons.component.html',
  styleUrls: ['./details-toggle-buttons.component.scss'],
})
export class DetailsToggleButtonsComponent {
  @Input() disabled = false;
  @Input() rejectDisabled = true;
  @Input() value: boolean | null | undefined = null;
  @Input() isNewAttachment!: boolean;

  click = new EventEmitter<boolean>();
  @Output() buttonToggleValue = this.click.pipe(
    map((value) => (value === this.value ? null : value))
  );

  @Output() supprimerEvent: EventEmitter<void> = new EventEmitter<void>();
}
