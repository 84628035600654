import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { trackByKey } from '@app/shared/helpers/object.helpers';
import {DetailItem, History, UpdateDetailReviewFn} from '@app/shared/types/details';
import {AuditFieldCode} from "@app/shared/types/claim";
import {DetailDataType} from "@app/shared/types/text-display-type";
import {Schedule} from "@app/shared/types/contract";
import {FieldValidation} from "@app/claims/types/action-button";
import {DetailReview, fieldRestruc} from "@app/claims/types/review";
import {FieldsValueCode} from "@app/shared/types/field-code";
import {forkJoin} from "rxjs";
import {Status} from "@app/shared/types/status";

@Component({
  selector: 'app-details-tab',
  templateUrl: './details-tab.component.html',
  styleUrls: ['./details-tab.component.scss'],
})
export class DetailsTabComponent implements OnInit, OnChanges {
  @Input() detailsList!: DetailItem[];
  @Input() histories!: History[];
  @Input() editionMode = false;
  @Input() status!: Status;
  @Input() updateDetailReviewFn?: UpdateDetailReviewFn;
  @Input() historyTransco!: string;

  @Output() hideActionButtonsEmitter: EventEmitter<FieldValidation> = new EventEmitter<FieldValidation>();
  @Output() updateMultipleFieldsValidation: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteDetailItem: EventEmitter<string> = new EventEmitter<string>();

  @Output() updateDetailToggleBtnEvent: EventEmitter<{ validFeedback: boolean, rejectDisabled: boolean }>
    = new EventEmitter<{validFeedback: boolean; rejectDisabled: boolean}>();

  nonEditableDetails: DetailItem[] = [];
  editableDetails: DetailItem[] = [];
  schedule: Schedule[] = [];
  trackByLabel = trackByKey<DetailItem>('label');

  scheduleToggle = false;

  historyHeader: string[] = [];

  auditFieldCodeToRemove: string[] = [
    AuditFieldCode.REJETCTROL.toString(),
    AuditFieldCode.REFCTRLJUR.toString(),
    AuditFieldCode.ACCCTRLJUR.toString(),
    AuditFieldCode.VALIDCTROL.toString(),
    AuditFieldCode.REJETVISA.toString(),
    AuditFieldCode.VISAMNGER.toString()
  ];
  @Input() updateFieldValidation!: { validFeedback: boolean, rejectDisabled: boolean };

  ngOnInit(): void {
    if (this.detailsList && this.detailsList[0] && this.detailsList[0].dataType === DetailDataType.DATATABLE) {
      this.schedule = this.detailsList[0].value as Schedule[];
    }

    this.historyHeader = [
      this.historyTransco + "audit-date",
      this.historyTransco + "user-control",
      this.historyTransco + "tracked-data",
      this.historyTransco + "comment",
      this.historyTransco + "user",
    ];

    this.generateDetails();
    this.filterHistory();
    this.accordionHeader();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailsList && !changes.detailsList.firstChange) {
      this.generateDetails();
    }
  }

  generateDetails(): void {
    if (this.editionMode) {
      [this.editableDetails, this.nonEditableDetails] = this.detailsList.reduce(
        (partition: [DetailItem[], DetailItem[]], detailItem) => {
          if (detailItem.canHaveFeedback) {
            partition[0].push(detailItem);
          } else {
            partition[1].push(detailItem);
          }
          return partition;
        },
        [[], []]
      );
    }
  }

  deleteDetailReview(detailItem: DetailItem) {
    this.editableDetails = this.editableDetails.filter(detail => detail !== detailItem);
    this.deleteDetailItem.emit(detailItem.fieldCode);
  }

  filterHistory() {
    this.histories = this.histories
      .filter(history => history.managementEventCode !== "MOTIFCONTR")
      .filter(history =>
        !(this.auditFieldCodeToRemove.includes(history.managementEventCode)
          && history.rejectedFieldCode && !history.comment)
      );
  }

  accordionHeader() {
    if (this.scheduleToggle) {
      return 'details.shared.schedules.click-to-hide-schedule'
    }
    return "details.shared.schedules.click-to-show-schedule";
  }

  updateDetailReview(update: { id: string, detailReview: DetailReview }): void {
    if (!update) {
      return;
    }
    if(!this.updateDetailReviewFn) {
      return
    }
    if (!fieldRestruc.includes(update.detailReview.fieldCode as FieldsValueCode)) {
      this.updateDetailReviewFn(
        update.id,
        update.detailReview
      ).subscribe();
    } else {
      forkJoin([
        this.updateDetailReviewFn(
          update.id,
          this.buildDetailReview(FieldsValueCode.OPERATIONAL_RANGE_AMORTIZATION_PROFILE_REQUESTED, update)
        ),
        this.updateDetailReviewFn(
          update.id,
          this.buildDetailReview(FieldsValueCode.PAYMENT_DUE_FREQUENCY_REQUESTED, update)
        ),
        this.updateDetailReviewFn(
          update.id,
          this.buildDetailReview(FieldsValueCode.EFFECTIVE_RESCHEDULING_DATE, update)
        ),
        this.updateDetailReviewFn(
          update.id,
          this.buildDetailReview(FieldsValueCode.PAYMENT_SCHEDULE_TERMINATION_DATE, update)
        ),
        this.updateDetailReviewFn(
          update.id,
          this.buildDetailReview(FieldsValueCode.INTEREST_RATE_REQUESTED, update)
        ),
        this.updateDetailReviewFn(
          update.id,
          this.buildDetailReview(FieldsValueCode.SCHEDULES, update)
        ),
        this.updateDetailReviewFn(
          update.id,
          this.buildDetailReview(FieldsValueCode.OUTSTANDING_PRINCIPAL_AT_REARRANGEMENT_DATE, update)
        ),
        this.updateDetailReviewFn(
          update.id,
          this.buildDetailReview(FieldsValueCode.SUSPENDED_PAYMENT_DUE_NUMBER, update)
        )
      ]).subscribe(() => {
        if (update.detailReview.validated !== undefined) {
          this.updateDetailToggleBtnEvent.emit({
            validFeedback: update.detailReview.validated,
            rejectDisabled: update.detailReview.validated
          });
          this.updateMultipleFieldsValidation.emit(update.detailReview.validated);
        }
      });
    }
  }

  private buildDetailReview(fieldsValueCode: FieldsValueCode, update: { id: string, detailReview: DetailReview }):
    DetailReview {
    const detailReview: DetailReview = {
      fieldCode: fieldsValueCode,
      taskId: update.id,
      validated: update.detailReview.validated,
    }

    if (fieldsValueCode === update.detailReview.fieldCode) {
      detailReview.comment = update.detailReview.comment;
    }

    return detailReview;
  }
}
