<div class="detail-feedback-history" *ngIf="feedbackHistory && feedbackHistory.length">
  <h4 class="detail-feedback-history__title">{{ 'details.shared.validation.comment-history' | transloco }}</h4>
  <ul class="detail-feedback-history__list">
    <li
      class="detail-feedback-history__list-item"
      *ngFor="let comment of feedbackHistory; trackBy:trackByIndex"
    >{{ comment.date | date: "dd-MM-yyyy" }} {{comment.author}} : {{comment.text}}</li>
  </ul>
</div>


<textarea *ngIf="!isNewAttachment"
  cdkTextareaAutosize
  matInput
  #autosize="cdkTextareaAutosize"
  [formControl]="commentFormControl"
  placeholder="{{ 'details.shared.validation.comment-placeholder' | transloco}}"
></textarea>


