import { Component, Input } from '@angular/core';
import { AlertTypeColors } from "@app/shared/types/alert";
import { getIdFromLabel } from "@app/shared/utils/id-element.utils";

export type StepStatus = Partial<{
  statusColor: AlertTypeColors;
  statusLable: string;
  date?: string;
}>;

@Component({
  selector: 'app-steps-validation',
  templateUrl: './steps-validation.component.html',
  styleUrls: ['./steps-validation.component.scss']
})
export class StepsValidationComponent {
  @Input() stepsStatus: StepStatus[] = [];

  getIdFromLabel = getIdFromLabel;
}
