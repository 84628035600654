import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslocoConfig,
  TranslocoModule,
  TRANSLOCO_CONFIG,
} from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { HomeComponent } from "@app/pages/home/home.component";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { IconsService } from './core/services/icons.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './pages/login/login.component';
import { SharedModule } from './shared/shared.module';
import { translocoLoader } from './transloco.loader';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { AccessDeniedPageComponent } from './pages/access-denied-page/access-denied-page.component';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { SnackbarService } from './shared/services/snackbar.service';

registerLocaleData(localeFr, 'fr-FR');
export const DEFAULT_LANG = 'fr';
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoginComponent,
    HomeComponent,
    ErrorPageComponent,
    AccessDeniedPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslocoModule,
    CommonModule,
    TranslocoLocaleModule.init({
      langToLocaleMapping: {
        en: 'en-US',
        fr: 'fr-FR',
      },
    }),
    SharedModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        defaultLang: DEFAULT_LANG,
        availableLangs: [DEFAULT_LANG],
        // fallbackLang: 'fr',
        // missingHandler: {
        //   useFallbackTranslation: true,
        // },
        prodMode: environment.production,
      } as TranslocoConfig,
    },
    translocoLoader,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [SnackbarService],
    },
    // { provide: ErrorHandler, useClass: SentryErrorHandler },
    // ...getMockProviders(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(iconsServices: IconsService) {
    iconsServices.registerIcons();
  }
}
