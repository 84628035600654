<ng-container *ngIf="!isNewAttachment">
  <button
    [class.valid]="value === true"
    (click)="click.emit(true)"
    [disabled]="disabled"
  >
    <div class="icon"></div> {{ 'details.shared.validation.validate' | transloco }}
  </button>

  <button
    [class.invalid]="value === false"
    (click)="click.emit(false)"
    [disabled]="disabled || rejectDisabled"
  >
    <div class="icon"></div> {{ 'details.shared.validation.reject' | transloco }}
  </button>
</ng-container>

<button *ngIf="isNewAttachment"
        (click)="supprimerEvent.emit()"
        [class.valid]="value === true"> {{ 'details.shared.validation.delete' | transloco }}
</button>
