import { Routes } from '@angular/router';
import { HomeComponent } from "@app/pages/home/home.component";

import { PAGE_URL } from './app.constants';
import { AuthIsamGuard } from './core/guards/auth-isam.guard';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './pages/login/login.component';
import { SigninRedirectionPageComponent } from './pages/signin-redirection/signin-redirection.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { AccessDeniedPageComponent } from './pages/access-denied-page/access-denied-page.component';
import { UserAccessGuard } from './core/guards/user-access.guard';
import {
  admin_role_access,
  claims_role_access,
  declarations_role_access,
  groups_payment_role_access
} from "./shared/types/user";
import { PermissionAccessGuard } from "./core/guards/permission-access.guard";

export const APP_ROUTES: Routes = [
  // Dashboard
  {
    path: PAGE_URL.SIGNIN,
    component: LoginComponent,
  },

  {
    path: 'sso/code',
    component: SigninRedirectionPageComponent,
  },

  {
    path: PAGE_URL.HOME,
    component: HomeComponent,
    canActivate: [AuthIsamGuard, UserAccessGuard],
  },
  {
    path: PAGE_URL.DASHBOARD,
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthIsamGuard, UserAccessGuard, PermissionAccessGuard],
    data: {
      roles: claims_role_access
    }
  },

  {
    path: PAGE_URL.CLAIM,
    loadChildren: () =>
      import('./claims/claims.module').then((m) => m.ClaimsModule),
    canActivate: [AuthIsamGuard, UserAccessGuard, PermissionAccessGuard],
    data: {
      roles: claims_role_access
    }
  },

  {
    path: PAGE_URL.DECLARATION,
    loadChildren: () =>
      import('./declarations/declarations.module').then((m) => m.DeclarationsModule),
    canActivate: [AuthIsamGuard, UserAccessGuard, PermissionAccessGuard],
    data: {
      roles: declarations_role_access
    }
  },

  {
    path: PAGE_URL.CONTRACTS,
    loadChildren: () =>
      import('./contracts/contracts.module').then((m) => m.ContractsModule),
    canActivate: [AuthIsamGuard, UserAccessGuard],
  },

  {
    path: PAGE_URL.GROUPS_PAYEMENT,
    loadChildren: () =>
      import('./group-payment/groups-payment.module').then((m) => m.GroupsPaymentModule),
    canActivate: [AuthIsamGuard, UserAccessGuard, PermissionAccessGuard],
    data: {
      roles: groups_payment_role_access
    }
  },

  {
    path: PAGE_URL.ADMIN,
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthIsamGuard, UserAccessGuard, PermissionAccessGuard],
    data: {
      roles: admin_role_access
    }
  },

  {
    path: PAGE_URL.ACCESS_DENIED,
    component: AccessDeniedPageComponent,
    canActivate: [AuthIsamGuard],
  },
  {
    path: PAGE_URL.ERROR,
    component: ErrorPageComponent,
    canActivate: [AuthIsamGuard],
  },

  // Redirect
  { path: '', redirectTo: PAGE_URL.HOME, pathMatch: 'full' },
  // NotFound
  { path: '**', component: NotFoundComponent },
];
