import {AccountService} from "@app/core/auth/account.service";
import {Router} from "@angular/router";
import {UserRole} from "@app/shared/types/user";
import {PAGE_URL} from "@app/app.constants";
import {catchError} from "rxjs/operators";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class HomePageAccessManager {
  constructor(private accountService: AccountService, private router: Router) {}

  accessHomePage() {
    this.accountService.getCurrentUser().subscribe(currentUser => {
        if (currentUser) {
          switch (currentUser.role) {
            case UserRole.ROLE_HEALTHY_MANAGER:
              this.router.navigate([PAGE_URL.CONTRACTS]);
              break;
            case UserRole.ROLE_ACCOUNTANT:
              this.router.navigate([PAGE_URL.GROUPS_PAYEMENT]);
              break;
            case UserRole.ROLE_ADMIN:
            case UserRole.ROLE_MANAGER:
            case UserRole.ROLE_JUNIOR_LAWYER:
            case UserRole.ROLE_SENIOR_LAWYER:
            case UserRole.ROLE_CONSULTANT:
              this.router.navigate([PAGE_URL.DASHBOARD]);
              break;
            default: {
              this.router.navigate([PAGE_URL.ACCESS_DENIED]);
              break;
            }
          }
        } else {
          throw new Error();
        }
      },
      catchError(() => {
        return this.router.navigate([PAGE_URL.ACCESS_DENIED]);
      })
    );
  }
}
