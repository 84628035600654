import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { GenericRedirectionComponent } from '@app/pages/generic-redirection/generic-redirection.component';
import { SigninRedirectionPageComponent } from '@app/pages/signin-redirection/signin-redirection.component';
import { HeaderComponent } from '@app/shared/components/header/header.component';
import { BpiButtonModule } from '@bpi/shared-components/ui-component-future';
import {
  BpiSharedUiComponentsModule,
  CardModule,
} from '@bpi/shared-components/ui-components';
import { TranslocoModule } from '@ngneat/transloco';
import {
  TranslocoCurrencyPipe,
  TranslocoLocaleModule,
} from '@ngneat/transloco-locale';
import { TableModule } from "primeng/table";
import { PaginatorModule } from "primeng/paginator";
import { ModalService } from "@app/shared/components/modals/services/modal.service";
import { ModalFactory } from "@app/shared/components/modals/factory/modal-factory.component";
import {
  ConfirmModalComponent
} from "@app/shared/components/modals/components/confirmation-modal/confirm-modal.component";
import { TagModule } from "primeng/tag";
import { ButtonModule } from "primeng/button";
import { TooltipModule } from 'primeng/tooltip';
import {DetailsTabComponent} from "@app/shared/components/details-tab/details-tab.component";
import {
  DetailsToggleButtonsComponent
} from "@app/shared/components/details-validation/details-toggle-buttons/details-toggle-buttons.component";
import {DetailsScheduleComponent} from "@app/shared/components/details-schedule/details-schedule.component";
import {DetailsValidationComponent} from "@app/shared/components/details-validation/details-validation.component";
import {
  DetailsFeedbackComponent
} from "@app/shared/components/details-validation/details-feedback/details-feedback.component";
import {AccordionModule} from "primeng/accordion";
import {TextFieldModule} from "@angular/cdk/text-field";

import { AlternateDetailsGridComponent } from './components/alternate-details-grid/alternate-details-grid.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { ClaimCardComponent } from './components/claim-card/claim-card.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { DetailComponent } from './components/detail/detail.component';
import { GridDetailsListComponent } from './components/grid-details-list/grid-details-list.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { SidebarCardComponent } from './components/sidebar-card/sidebar-card.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { StatusBadgeComponent } from './components/status-badge/status-badge.component';
import { ToggleArrowButtonComponent } from './components/toggle-arrow-button/toggle-arrow-button.component';
import { DisplayModeDirective } from './directives/display-mode.directive';
import { DetailsTypesService } from './services/details-types/details-types.service';
import { SharedInfoService } from './services/shared-info/shared-info.service';
import { SnackbarService } from './services/snackbar.service';
import { ChristmasLightsComponent } from './components/christmas-lights/christmas-lights.component';
import { SelectGroupsPaymentCardComponent } from './components/select-groups-payment-card/select-groups-payment-card.component';
import { CanVisualizeDirective } from './directives/can-visualize.directive';
import { ToMonthDatePipe } from './pipes/to-month-date.pipe';
import { StepsValidationComponent } from './components/steps-validation/steps-validation.component';
import { InformationModalComponent } from './components/modals/components/information-modal/information-modal.component';
import { DropdownModalComponent } from './components/modals/components/dropdown-modal/dropdown-modal.component';
import {DeclarationCardComponent} from "./components/declaration-card/declaration-card.component";

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    ContactCardComponent,
    InfoCardComponent,
    SidebarCardComponent,
    BackButtonComponent,
    StatusBadgeComponent,
    GridDetailsListComponent,
    DetailComponent,
    AlternateDetailsGridComponent,
    SnackbarComponent,
    DisplayModeDirective,
    ToggleArrowButtonComponent,
    SigninRedirectionPageComponent,
    GenericRedirectionComponent,
    ClaimCardComponent,
    ChristmasLightsComponent,
    SelectGroupsPaymentCardComponent,
    CanVisualizeDirective,
    ToMonthDatePipe,
    StepsValidationComponent,
    ConfirmModalComponent,
    ModalFactory,
    InformationModalComponent,
    DropdownModalComponent,
    DeclarationCardComponent,
    DetailsTabComponent,
    DetailsValidationComponent,
    DetailsFeedbackComponent,
    DetailsToggleButtonsComponent,
    DetailsScheduleComponent
  ],
  exports: [
    HeaderComponent,
    CardModule,
    BpiSharedUiComponentsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRippleModule,
    MatIconModule,
    MatDividerModule,
    SidebarComponent,
    BpiButtonModule,
    ContactCardComponent,
    InfoCardComponent,
    SidebarCardComponent,
    BackButtonComponent,
    StatusBadgeComponent,
    GridDetailsListComponent,
    DetailComponent,
    AlternateDetailsGridComponent,
    MatSnackBarModule,
    MatDialogModule,
    DisplayModeDirective,
    ToggleArrowButtonComponent,
    MatCardModule,
    GenericRedirectionComponent,
    ClaimCardComponent,
    MatProgressBarModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    ChristmasLightsComponent,
    SelectGroupsPaymentCardComponent,
    CanVisualizeDirective,
    ToMonthDatePipe,
    StepsValidationComponent,
    ModalFactory,
    DeclarationCardComponent,
    DetailsTabComponent,
  ],
  imports: [
    RouterModule,
    CardModule,
    CommonModule,
    BpiSharedUiComponentsModule,
    TranslocoModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRippleModule,
    MatIconModule,
    BpiButtonModule,
    MatDividerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatDialogModule,
    MatCardModule,
    TranslocoLocaleModule,
    MatProgressBarModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    TableModule,
    PaginatorModule,
    TagModule,
    ButtonModule,
    TooltipModule,
    ScrollingModule,
    AccordionModule,
    TextFieldModule
  ],
  providers: [
    DatePipe,
    TranslocoCurrencyPipe,
    DetailsTypesService,
    SnackbarService,
    DisplayModeDirective,
    SharedInfoService,
    CanVisualizeDirective,
    ToMonthDatePipe,
    ModalService
  ],
  entryComponents: [SnackbarComponent],
})
export class SharedModule {}
