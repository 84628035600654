import { FieldsAttachmentCode, FieldsCode, FieldsValueCode } from '@app/shared/types/field-code';

export interface DetailReview {
  fieldCode: FieldsCode | '';
  comment?: string;
  commentDate?: Date;
  commentAuthor?: string;
  managerComment?: string;
  managerCommentDate?: Date;
  managerCommentAuthor?: string;
  missing?: boolean;
  taskId: string;
  validated?: boolean;
}

export type DetailReviewUpdate = Pick<DetailReview, 'comment' | 'validated'>;

export interface DetailReviewItems {
  items: DetailReview[];
}

export const fieldRestruc: FieldsValueCode[] = [
  FieldsValueCode.OPERATIONAL_RANGE_AMORTIZATION_PROFILE_REQUESTED,
  FieldsValueCode.PAYMENT_DUE_FREQUENCY_REQUESTED,
  FieldsValueCode.EFFECTIVE_RESCHEDULING_DATE,
  FieldsValueCode.PAYMENT_SCHEDULE_TERMINATION_DATE,
  FieldsValueCode.INTEREST_RATE_REQUESTED,
  FieldsValueCode.SUSPENDED_PAYMENT_DUE_NUMBER,
  FieldsValueCode.OUTSTANDING_PRINCIPAL_AT_REARRANGEMENT_DATE,
  FieldsValueCode.REARRANGEMENT_FEES_AMOUNT,
  FieldsValueCode.SCHEDULES
];

export const attachmentFieldClaimRestruct: FieldsAttachmentCode[] = [
  FieldsAttachmentCode.ADDITIONALITY_CRITERION_PROOF,
  FieldsAttachmentCode.AMORTIZATION_TABLE,
  FieldsAttachmentCode.BACKUP_PLAN,
  FieldsAttachmentCode.CERTIFIED_ACCOUNTANT_CERTIFICATE,
  FieldsAttachmentCode.LOAN_AGREEMENT,
  FieldsAttachmentCode.PROTOCOL,
  FieldsAttachmentCode.TAX_RETURN,
  FieldsAttachmentCode.STATUTORY_AUDITOR_CERTIFICATE,
  FieldsAttachmentCode.RECOVERY_PLAN,
  FieldsAttachmentCode.NONASSIGNMENT_DEBT_CERTIFICATE,
  FieldsAttachmentCode.DEBT_DECLARATION,
  FieldsAttachmentCode.DEBT_COUNTDOWN,
  FieldsAttachmentCode.ENDORSEMENT,
  FieldsAttachmentCode.LEGAL_NOTICE_EXTRACT,
];

export const emptyFieldToHide: FieldsValueCode[] = [
  FieldsValueCode.PAYROLL_REQUESTED,
  FieldsValueCode.COMPANY_REVENUE
];
