// eslint-disable-next-line no-shadow
export enum UserRole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_CONSULTANT = 'ROLE_CONSULTANT',

  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_SENIOR_LAWYER = 'ROLE_SENIOR_LAWYER',
  ROLE_JUNIOR_LAWYER = 'ROLE_JUNIOR_LAWYER',

  ROLE_ACCOUNTANT = 'ROLE_ACCOUNTANT',
  ROLE_HEALTHY_MANAGER = 'ROLE_HEALTHY_MANAGER',

  ROLE_DEFAULT = ' '
}

// eslint-disable-next-line no-shadow
export enum UserStatus {
  ACTIF = 'ACTIF',
  INACTIF = 'INACTIF'
}

export interface User {
  role: UserRole;
  status: UserStatus;
  selfcareId: string;
  firstName: string;
  lastName: string;
  email: string;
}

export const declarations_role_access = [
  UserRole.ROLE_ADMIN,
  UserRole.ROLE_MANAGER,
  UserRole.ROLE_JUNIOR_LAWYER,
  UserRole.ROLE_SENIOR_LAWYER,
  UserRole.ROLE_CONSULTANT,
];

export const claims_role_access = [
  UserRole.ROLE_ADMIN,
  UserRole.ROLE_MANAGER,
  UserRole.ROLE_JUNIOR_LAWYER,
  UserRole.ROLE_SENIOR_LAWYER,
  UserRole.ROLE_CONSULTANT,
];

export const claims_lawyer_access = [
  UserRole.ROLE_ADMIN,
  UserRole.ROLE_MANAGER,
  UserRole.ROLE_JUNIOR_LAWYER,
  UserRole.ROLE_SENIOR_LAWYER,
  UserRole.ROLE_CONSULTANT,
];

export const claims_lawyer_action_access = [
  UserRole.ROLE_ADMIN,
  UserRole.ROLE_MANAGER,
  UserRole.ROLE_JUNIOR_LAWYER,
  UserRole.ROLE_SENIOR_LAWYER,
];

export const claims_accountant_action_access = [
  UserRole.ROLE_ADMIN,
  UserRole.ROLE_ACCOUNTANT
];

export const USER_ROLE_TRANSLATION: {
  [type in UserRole]: string;
} = {
  [UserRole.ROLE_ADMIN]: "admin.user.user-roles.admin",
  [UserRole.ROLE_MANAGER]: "admin.user.user-roles.manager",
  [UserRole.ROLE_SENIOR_LAWYER]: "admin.user.user-roles.senior-lawyer",
  [UserRole.ROLE_JUNIOR_LAWYER]: "admin.user.user-roles.junior-lawyer",
  [UserRole.ROLE_ACCOUNTANT]: "admin.user.user-roles.accountant",
  [UserRole.ROLE_HEALTHY_MANAGER]: "admin.user.user-roles.healthy-manager",
  [UserRole.ROLE_CONSULTANT]: "admin.user.user-roles.consultant",
  [UserRole.ROLE_DEFAULT]: "admin.user.user-roles.default",
};

export const USER_STATUS_TRANSLATION: {
  [type in UserStatus]: string;
} = {
  [UserStatus.ACTIF]: "admin.user.user-status.active",
  [UserStatus.INACTIF]: "admin.user.user-status.inactive",
};

export const groups_payment_role_access = [UserRole.ROLE_ADMIN, UserRole.ROLE_ACCOUNTANT, UserRole.ROLE_CONSULTANT]
export const admin_role_access = [UserRole.ROLE_ADMIN]
