<app-details-toggle-buttons
  *ngIf="isEditable && isTobeChecked"
  [value]="validFeedback"
  [disabled]="!isEditable"
  [rejectDisabled]="rejectDisabled"
  [isNewAttachment]="isNewAttachment"
  (buttonToggleValue)="onValidationChange($event)"
  (supprimerEvent)="deleteReview()"
></app-details-toggle-buttons>

<div class="details-validation__feedback" [ngClass]="{ valid: (validFeedback && isTobeChecked), invalid: (validFeedback === false && isTobeChecked)}">
  <div class="details-validation__feedback-inputs">
    <div class="detail-validation__item">
      <app-detail [detailItem]="detailItem"></app-detail>
    </div>

    <app-details-feedback
      [formGroup]="formGroup"
      [isValid]="isValid.value"
      [feedbackHistory]="detailItem.feedback?.comments"
      [isNewAttachment]="isNewAttachment"
      [disabled]="!isEditable"
    ></app-details-feedback>
  </div>
</div>
