<bpi-styled-modal-dialog
  id="dropdown-dialog"
  [title]="dropdownModal.title"
  [image]="'assets/images/feedback-confirmation-modal.svg'"
  [isOpen]="modalInstance.isOpen"
  [closeable]="true"
  [primaryButton]="dropdownModal.validation"
  [secondaryButton]="dropdownModal.cancellation ? dropdownModal.cancellation : ''"
  (onConfirm)="close(inputValue())"
  (onSecondary)="close()"
  (onClose)="close()"
>
  <div id="dropdown-dialog__container" [ngStyle]="{ display: 'flex', 'flex-direction': 'column', gap: '1rem' }">

    <div id="dropdown-dialog__select" [ngStyle]="{ display: 'flex', 'flex-direction': 'column', gap: '0.5rem' }">
      <h4 [ngStyle]="{ display: 'flex', 'align-items': 'start' }"> Motif de rejet </h4>

      <bpi-styled-select *ngIf="dropdownModal && dropdownModal.items"
                         [choices]="dropdownModal.items"
                         [inputFormControl]="itemChoice"
                         [hideErrorMessage]="true"
                         id="selector">
      </bpi-styled-select>
    </div>

    <div id="dropdown-dialog__text-input" *ngIf="dropdownModal && dropdownModal.showComment"
         [ngStyle]="{ display: 'flex', 'flex-direction': 'column', gap: '0.5rem' }">
      <h4 [ngStyle]="{ display: 'flex', 'align-items': 'start' }"> Commentaire </h4>

      <bpi-styled-long-text-input [inputFormControl]="comment"></bpi-styled-long-text-input>
    </div>
  </div>
</bpi-styled-modal-dialog>
